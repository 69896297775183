import React, { createContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AuthContext = createContext();

function App() {
    return (
        <Container fluid className="App">
            <Row>
                <Col>
                    <h1>
                        <FontAwesomeIcon icon={faHammer} className="bounce" />
                        Website under construction
                        <FontAwesomeIcon icon={faHammer} className="bounce" />
                    </h1>
                </Col>
            </Row>
        </Container>
    );
}
export default App;
